import { Component, OnInit } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { SEOService } from '../seoservice.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  name:'';
  email: '';
  message:'';

  constructor(private http: HttpClient, private seoService: SEOService) { }

  ngOnInit(): void {
    this.createLinkForCanonicalURL();
  }

  createLinkForCanonicalURL() {
    this.seoService.createLinkForCanonicalURL();
  } 

  submitForm(data){
    this.http.post('https://hm18665jk7.execute-api.eu-central-1.amazonaws.com/noiam/contactMe/contact-us', data)
    .subscribe((result) => {
      console.warn("result", result)
    })
    console.warn(data);

    const message = 'Thanks, we will get back to you!';
    alert(message);

  }
}


// import { Component, OnInit } from '@angular/core';
// import { HttpClient } from "@angular/common/http";

// @Component({
//   selector: 'app-contact',
//   templateUrl: './contact.component.html',
//   styleUrls: ['./contact.component.css']
// })
// export class ContactComponent implements OnInit {
//   name:'';
//   email: '';
//   message:'';

//   constructor(private http: HttpClient) { }

//   ngOnInit(): void {
//   }

//   submitForm(data){
//     this.http.post('https://hm18665jk7.execute-api.eu-central-1.amazonaws.com/noiam/contactMe/contact-us', data)
//     .subscribe((result) => {
//       console.warn("result", result)
//     })
//     console.warn(data);

//     const message = 'Thanks, we will get back to you!';
//     alert(message);

//   }
// }


