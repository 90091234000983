import { Component, OnInit, PLATFORM_ID, Inject } from '@angular/core';
import { SEOService } from '../seoservice.service';
// import { trigger, state, style, animate, transition } from '@angular/animations';
import AOS from 'aos';
import { isPlatformBrowser} from '@angular/common';

// const style1 = style({
//   opacity:1,
//   transform: "translateX(0)"
// })

// const style2 = style({
//   opacity: 0,
//   transform: "translateX(-100%)"
// })

@Component({
  selector: 'app-saas',
  templateUrl: './saas.component.html',
  styleUrls: ['./saas.component.css']
})

export class SaasComponent implements OnInit {

  constructor(private seoService: SEOService, @Inject(PLATFORM_ID) private platformId: Object) { }

  ngOnInit(): void {
    this.createLinkForCanonicalURL();
    // AOS.init();
    if (isPlatformBrowser(this.platformId)) { AOS.init({ once: true, duration: 500 }); }

  }

  createLinkForCanonicalURL() {
    this.seoService.createLinkForCanonicalURL();
  } 
  
}
