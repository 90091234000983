<div class="row g-5">
  <div class="col-5"> </div> 
  <div class="d-grid col-3 mx-auto">
    <a  [routerLink]="['/contact']" class="btn btn-outline-primary me-3" role="button" aria-pressed="true" data-aos="zoom-in">Book a demo</a>
  </div>
</div>


<div class="card p-5" data-aos="fade-right" data-aos-duration="3000">
  <div class="row gx-5 justify-content-around">
    <div class="col-md-3">
      <img src="./assets/AI-engine.webp" class="img-fluid rounded" alt="VibeZ AI Forecast Engine">
    </div>
    <div class="col-md-4 align-self-center">
      <div class="card-body">
        <h5 class="card-title">AI Forecast Engine</h5>
        <p class="card-text" >The VibeZ AI engine continuously generates an accurate supply/demand forecast for the day ahead.
          Complies with market regulation and reduces balancing costs.</p>
      </div>
    </div>
  </div>
</div>

<div class="card text-bg-light p-5" data-aos="fade-left" data-aos-duration="3000">
  <div class="row justify-content-center">
    <div class="col-md-4 align-self-center">
      <h5 class="card-title">Decision Engine</h5>
      <p class="card-text">VibeZ is an autonomus supplier of green energy.<br> 
        Data - driven matching between numerous demand & supply profiles.
        Integrating with any 3rd party & fully automated trading.</p>
    </div>
    <div class="col-md-3">     
      <img src="./assets/tech.svg" class="img-fluid rounded" alt="VibeZ Technology">
  </div>
  </div>
</div>  

<div class="card p-5" data-aos="fade-up" data-aos-duration="3000"> 
  <div class="row gx-5 justify-content-around">
    <div class="col-md-3 offset-md-1">
      <iframe width="100%" height="500" src="./assets/globe.html" allowfullscreen="allowfullscreen" frameborder="0"></iframe>
    </div>
    <div class="col-md-4 align-self-center">
      <div class="card-body">
        <h5 class="card-title">It's all about Data</h5>
        <p class="card-text" >Whether you are an energy supplier, producer, consumer or owner of energy assets,<br>
          Get your data clean and in a friendly UI to mine knowledge and make the right business choices.</p>
      </div>
    </div>
  </div>
</div>

<div class="card text-bg-light p-5" data-aos="fade-left" data-aos-duration="3000">
  <div class="card text-bg-light p-5">
  <div class="row justify-content-center">
    <div class="col-md-4 align-self-center">
      <h5 class="card-title">Unleash Renewable Energy</h5>
      <p class="card-text" >VibeZ digitizes energy assets; Tagging each energy unit source & price. <br> Easing consumer access to renewable energy.<br>
        Automating power purchase agreements for producers.</p>
    </div>
    <div class="col-md-3">     
      <img src="./assets/VibeZ Insights.png" class="img-fluid rounded"  alt="VibeZ Insights">
  </div>
  </div>
</div>
  

  
