import {
    transition,
    trigger,
    query,
    style,
    animate,
    group
} from '@angular/animations';
// import { Optional } from '@angular/core';

export const slideInAnimation =
    trigger('routeAnimations', [
        transition('Home => *', [
            query(':enter, :leave', style({ position: 'fixed', width: '100%' }), { optional: true }),
            group([
                query(':enter', [
                    style({ transform: 'translateY(100%)' }),
                    animate('0.6s ease-in-out', style({ transform: 'translateY(0%)', opacity:0.9}))
                ], { optional: true }),
                query(':leave', [
                    style({ transform: 'translateY(0%)' }),
                    animate('0.7s ease-in-out', style({transform: 'translateY(-100%)', opacity:0.2}))
                ], { optional: true })
            ])
        ]),
        transition('* => Home', [
            query(':enter, :leave', style({ position: 'fixed', width: '100%' }), { optional: true }),
            group([
                query(':enter', [
                    style({ transform: 'translateY(-100%)' }),
                    animate('0.5s ease-in-out', style({ transform: 'translateY(0%)' }))
                ], { optional: true }),
                query(':leave', [
                    style({ transform: 'translateY(0%)' }),
                    animate('0.5s ease-in-out', style({ transform: 'translateY(100%)' }))
                ], { optional: true })
            ])
        ]),
        transition('Article => *', [
            query(':enter, :leave', style({ position: 'fixed', width: '100%' }), { optional: true }),
            group([
                query(':enter', [
                    style({ transform: 'translateY(100%)' }),
                    animate('0.6s ease-in-out', style({ transform: 'translateY(0%)', opacity:0.9}))
                ], { optional: true }),
                query(':leave', [
                    style({ transform: 'translateY(0%)' }),
                    animate('0.7s ease-in-out', style({transform: 'translateY(-100%)', opacity:0.2}))
                ], { optional: true })
            ])
        ]),
        transition('* => Article', [
            query(':enter, :leave', style({ position: 'fixed', width: '100%' }), { optional: true }),
            group([
                query(':enter', [
                    style({ transform: 'translateY(-100%)' }),
                    animate('0.5s ease-in-out', style({ transform: 'translateY(0%)' }))
                ], { optional: true }),
                query(':leave', [
                    style({ transform: 'translateY(0%)' }),
                    animate('0.5s ease-in-out', style({ transform: 'translateY(100%)' }))
                ], { optional: true })
            ])
        ])
    ]);